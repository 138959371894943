/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var browserDetect = function() {
          var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          var is_chrome = !!window.chrome && !is_opera;
          var is_explorer= typeof document !== 'undefined' && !!document.documentMode && !isEdge;
          var is_firefox = typeof window.InstallTrigger !== 'undefined';
          var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          if (is_safari) {
            $('html.site body').addClass('safari');
          } 

        };

        browserDetect();

        var mobileMenu_btn = function () {
            $('#mobile-menu__btn').click(function(){
              $(this).toggleClass('active');
              $('.header__wrapper').toggleClass('no_shadow');
              $('.mobile-menu').slideToggle();
            });
        };

        mobileMenu_btn();

       var resize = function () {
          $(window).resize(function(){

            if ($(window).width() < 992 ) {

              if($('#mobile-menu__btn').is('.active')) {
                $('.mobile-menu').show();
                $('.header__wrapper').addClass('no_shadow');
              } else {
                $('.mobile-menu').hide();
                $('.header__wrapper').removeClass('no_shadow');
              }

            } else {
              $('.mobile-menu').hide();
              $('.header__wrapper').removeClass('no_shadow');
            }
            
            
          });
        };
        resize();


        if ($('.page-header__hero-bg').length) {
          var rellax = new Rellax('.rellax', {
            center: true
          });  
        }
        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // Rellax
        var rellax = new Rellax('.rellax', {
          center: true
        });


        // Scrolling animations
        var shape_transform = function() {

          var distance = $('#section_2').offset().top - 500,
              $window = $(window);

          var distance2 = $('#section_7').offset().top - 500;

          $window.scroll(function() {
              if ( $window.scrollTop() >= distance ) {
                  // Your div has reached the top
                  $('.motion__magnifier').addClass('fix');
                  // $('.left-el').removeClass('bounce-to-left');
                  // $('.right-el').removeClass('bounce-to-right');
              } else {
                $('.motion__magnifier').removeClass('fix');
                // $('.left-el').addClass('bounce-to-left');
                // $('.right-el').addClass('bounce-to-right');
              }
          });
        
        };

        shape_transform();

        $(window).on('resize', function () {
            shape_transform();
        });





      // Waypoints
      $('.motion__trigger')
        //.fadeOut(0) // immediately hide element
        .waypoint(function(direction) {
          if (direction === 'down') {
            $(this.element).find('.motion__el-container').addClass('animate-start');
          }
          else {
            $(this.element).find('.motion__el-container').removeClass('animate-start');
          }
        }, {
          offset: 450
        });

       $('.motion__trigger-btm')
        //.fadeOut(0) // immediately hide element
        .waypoint(function(direction) {
          if (direction === 'down') {
            $(this.element).find('.motion__el-container').addClass('animate-start');
          }
          else {
            $(this.element).find('.motion__el-container').removeClass('animate-start');
          }
        }, {
          offset: 650
        });

        

        // Slider
        $('.quote').slick({
           accessibility: false,
           dots: true,
           slidesToShow: 1,
           slidesToScroll: 1,
           infinite: true,
           cssEase: 'linear'
        });

        // Vertical Slider
        $('.vertical-slider').slick({
           accessibility: false,
           dots: false,
           vertical: true,
           verticalSwiping: true,
           slidesToShow: 1,
           slidesToScroll: 1,
           infinite: true,
           centerMode: true,
           focusOnSelect: true,
           centerPadding: '110px',
           cssEase: 'linear',
           responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                vertical: false,
                verticalSwiping: false,
                centerMode: true,
                centerPadding: '20px',
                focusOnSelect: true
              }
            },
             {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                vertical: false,
                verticalSwiping: false,
                centerMode: false,
                centerPadding: '0px',
                focusOnSelect: true
              }
            }
           ]
        });

        $('.v-slider__nav-up').click(function(){
            $(".vertical-slider").slick('slickPrev');
        });

        $('.v-slider__nav-down').click(function(){
            $(".vertical-slider").slick('slickNext');
        });


        var $status = $('.v-slider__nav-index');
        var $slickElement = $('.vertical-slider');
        var $toalSlides = $('.slider-item').not( '.slick-cloned' ).length;

        $(document).ready(function(){

          $status.text('1 of ' + $toalSlides );
        });

        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + ' of ' + slick.slideCount);
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_how_we_work': {
      init: function() {


        var url = document.location.toString();

        if (url.match('#')) {

            // Tabs
            $('.nav-tabs a').removeClass('active');
            $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');

            // Accordion
            $('.how-we-work__accordion .card .card-header button').addClass('collapsed');
            $('.how-we-work__accordion .card .collapse').removeClass('show');
            $('.how-we-work__accordion .card #heading_' + url.split('#')[1] ).find('button').removeClass('collapsed');
            $('.how-we-work__accordion .card #collapse-' + url.split('#')[1] ).addClass('show');

            //console.log(url.split('#')[1]);


            if ($(window).width() < 768 ) {

              $('html, body').animate({
                scrollTop: $('#how-we-work__accordion').offset().top - 100
              },1000);

            } else {

              $('html, body').animate({
                scrollTop: $('#tabContent').offset().top - 400
              },1000);

            }
            
            
            

        } 

        // // Change hash for page-reload
        // $('.nav-tabs a').on('shown.bs.tab', function (e) {
        //     //window.location.hash = e.target.hash;
        //     // url.find('#').
        // });

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        // Rellax
        
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
